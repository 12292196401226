import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

const ele = document.getElementById('root')
if(ele) {
  const root = ReactDOM.createRoot(ele)
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
