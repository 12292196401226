
export const sessionId = {
    profile: 'ID_profiles',
    workDepartment: 'ID_workdepartment',
    applicationType: 'ID_applicationType',
    contractType: 'ID_contractType',
    contractExtra: 'ID_contractExtra',
    proflieTag: 'ID_profileTag',
    notPresentType: 'ID_notPresentType',
    token: "ID_token",
    roles: "ID_roles",
    workTimes: "ID_worktimes",
    timetable: "ID_timetable",
    timetableTemplate: "ID_timetabletemplate",
    contractJobTitle: "ID_contractJobTitle",
    contractSalaryLevel: "ID_contractSalaryLevel",
}