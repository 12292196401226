import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import store from './store'
import { sessionId } from './localStorage';
import Login from './views/Login/Login'
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context'
import { access } from 'fs'
import { WebStorageStateStore } from 'oidc-client-ts'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Logout = React.lazy(() => import('./views/Logout/Logout'))


interface IProps {
  children: JSX.Element
}
function RequireAuth(p: IProps) {
  let location = useLocation()

  let auth = store.getState().isAuthenticated

  if (!auth) {
    return (
      <Navigate to='/login' state={{ from: location }} replace />
    )
  }

  return p.children
}

function App() {
  return (
    <OauthWrapper>
      <AppRoutes />
    </OauthWrapper>
  )
}



// app to load and authenticate routes
function AppRoutes() {
  const auth = useAuth();
  const appState = store.getState();

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      if (confirm("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
        auth.signinSilent();
      }
    })
  }, [auth.events, auth.signinSilent]);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);



  // React.useEffect(() => {
  //   // get token from localStorage
  //   if (localStorage) {
  //     let token = localStorage.getItem(sessionId.token);
  //     if (token !== null && token !== "") {
  //       store.dispatch({
  //         type: 'set',
  //         token: token
  //       })
  //       store.dispatch({
  //         type: 'set',
  //         isAuthenticated: true
  //       })
  //     }

  //     // roles
  //     let roles = localStorage.getItem(sessionId.roles);
  //     if (roles !== null && roles !== "") {
  //       let rolesArray = JSON.parse(roles);

  //       store.dispatch({
  //         type: 'set',
  //         roles: rolesArray
  //       })
  //     }

  //     setPreload(false)
  //   }
  // }, [])
  //let appState = store.getState()
  //if (appState.isAuthenticated == false) {
  //  return <CSpinner color='primary' />
  //}

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated && appState.isAuthenticated) {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="*" element={
              <DefaultLayout />
            } />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;

}

export default App



function OauthWrapper(p: IProps) {

  if (localStorage) {
    let token = localStorage.getItem(sessionId.token);
    let roles = localStorage.getItem(sessionId.roles);

    if (roles !== null && roles !== "") {
      let rolesArray = JSON.parse(roles);
      store.dispatch({
        type: 'set',
        roles: rolesArray
      })
      console.log('OAuthWrapper: load roles to local storage')
    }

    if (token != null) {
      store.dispatch({
        type: 'set',
        token: token,
        isAuthenticated: true
      })
      console.log('OAuthWrapper: load token to local storage')
    }
  }

  return (
    <AuthProvider
      client_id='js'
      scope='openid role offline_access profile.api guest.api joblisting.api notification.api'
      automaticSilentRenew={true}
      authority={process.env.REACT_APP_IDENTITY_URL as string}
      redirect_uri={window.location.origin}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      onSigninCallback={(user) => {

        console.log(user);
        let rolesArray: any = [];
        let accesstoken: string = '';

        if (user?.access_token) {
          accesstoken = user?.access_token;
          if (localStorage) {
            localStorage.setItem(sessionId.token, accesstoken)
          }
        }

        console.log('token: ' + accesstoken)

        try {
          let tokenDataString: string = atob(accesstoken.split('.')[1]);
          console.log(tokenDataString)
          var tokenObj: { [id: string]: any } = JSON.parse(tokenDataString)
          rolesArray = tokenObj['role']
          if (Array.isArray(tokenObj['role']) == false) {
            rolesArray = [tokenObj['role']];
          }
          if (localStorage) {
            localStorage.setItem(sessionId.roles, JSON.stringify(rolesArray));
          }
        }
        catch {
          console.log("ERROR: parse roles in token")
        }

        if (user?.access_token) {
          accesstoken = user?.access_token;
        }

        store.dispatch({
          type: 'set',
          roles: rolesArray,
          token: accesstoken,
          isAuthenticated: accesstoken != '' ? true : false
        })



        // check for param **code** in location
        let locationSearch = window.location.search;
        if (locationSearch.indexOf("code") != -1) {
          window.history.replaceState
            ({},
              '',
              window.location.origin + window.location.hash)
        }

      }}
    >
      {p.children}
    </AuthProvider>
  )
}